export interface PickerProps {
  initData: AmPmTimeObj;
  width: number;
  open: EventTarget | null;
  handleClose: (value: string) => void;
}

export interface TimePickerProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export enum Shift {
  AM = 'AM',
  PM = 'PM'
}

export interface AmPmTimeObj {
  hour: string;
  minute: string;
  shift: Shift;
  timeString?: string;
}

export interface TimePickerFieldProps {
  value: any;
  handleChange: (timeValue: any) => void;
  placeHolder?: string;
}
