import { SafeAny } from '@models/common';

interface GTMConfig {
  containerId: string | null;
}

class GTM {
  containerId: string | null = null;

  initialized = false;

  configure(config: GTMConfig) {
    this.containerId = config.containerId;
  }

  initialize(config: GTMConfig) {
    if (this.initialized) {
      // console.log('Initialize - Already has GTM');
      return;
    }

    if (!config.containerId) {
      // console.log('Initialize - no GTM ID');
      return;
    }

    this.configure(config);

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    script.innerHTML = `
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${this.containerId}');
    `;
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${this.containerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    // console.log('GTM enabled');
    this.initialized = true;
  }

    // eslint-disable-next-line class-methods-use-this
    push(...args: SafeAny) {
      if (!(window as SafeAny).dataLayer) {
        (window as SafeAny).dataLayer = [];
      }
  
      (window as SafeAny).dataLayer.push(...args);
    }
}

// Singleton
export const gtm = new GTM();