import { Analytics } from '@components/Analytics';
import { getConsentForCategory } from '@components/ConsentCheck';
import { FacebookPixel } from '@components/FacebookPixel';
import Favicon from '@components/Favicon';
import { useAdminConfig } from '@queries/questionnaire';
import { MapProvider } from '@swyft/map';
import { FeatureNames } from '@swyft/ui';
import { useFeature } from '@utils/config';
import React, { useEffect, useState } from 'react';
import { AnalyticsRouter, NoAnalyticsRouter } from './AnalyticsRouter';

export const AppRoutes = (): React.ReactElement => {
  const { featureConfig } = useFeature(FeatureNames.Maps);
  const googleMapKey = featureConfig?.GoogleMapsApiKey || '';
  const { enabled: analyticFeatureEnabled, featureConfig: analyticsConfig } = useFeature(FeatureNames.Analytics);
  const { data: adminConfig } = useAdminConfig();
  const [hasAnalyticsConsent, setHasAnalyticsConsent] = useState(getConsentForCategory('analytics'));
  const [hasAdvertisingConsent, setHasAdvertisingConsent] = useState(getConsentForCategory('advertising'));

  useEffect(() => {
    const handleConsentChange = () => {
      setHasAnalyticsConsent(getConsentForCategory('analytics'));
      setHasAdvertisingConsent(getConsentForCategory('advertising'));
    };

    window.addEventListener('consentStatusUpdated', handleConsentChange);

    return () => {
      window.removeEventListener('consentStatusUpdated', handleConsentChange);
    };
  }, []);

  return (
    <MapProvider googleMapKey={googleMapKey}>
      <Analytics />
      <Favicon />
      {hasAnalyticsConsent && hasAdvertisingConsent && <FacebookPixel />}
      {hasAnalyticsConsent && analyticFeatureEnabled ? (
        <AnalyticsRouter
          siteId={analyticsConfig.MatomoSiteId}
          siteUrl={analyticsConfig.MatomoSiteUrl}
          tenantId={adminConfig?.id}
        />
      ) : (
        <NoAnalyticsRouter />
      )}
    </MapProvider>
  );
};
