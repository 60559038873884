import React from 'react';
import { Box, Switch, SwitchProps } from '@material-ui/core';
import { CheckOutlined, CloseOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { colors } from './theme';

export const DefaultSwitch = (props: SwitchProps) => {
  const classes = useStyles();
  return (
    <Box position='relative' width={'fit-content'}>
      <Switch {...props} />
      {props.checked ? (
        <CheckOutlined className={classes.checkIcon} />
      ) : (
        <CloseOutlined className={classes.closeIcon} />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  checkIcon: {
    position: 'absolute',
    fontSize: '0.875rem',
    top: 5,
    left: 16,
    color: colors.white
  },
  closeIcon: {
    position: 'absolute',
    fontSize: '0.875rem',
    top: 5,
    right: 16,
    color: colors.grey.DEFAULT
  }
});
