import { SafeAny } from '@models/common';
import { randomString } from '@utils/generate-id';
import { addYears } from 'date-fns';
import { useQuery } from 'react-query';
import { storageGet, storageSet } from './storage';

interface LOConfig {
  containerId: string | null;
}

class LuckyOrangeClass {
  containerId: string | null = null;
  initialized = false;
  configure(config: LOConfig) {
    this.containerId = config.containerId;
  }

  initialize(config: LOConfig) {
    if (this.initialized) {
      return;
    }

    if (!config.containerId) {
      console.log('no LuckOrange ID');
      return;
    }

    this.configure(config);

    const script = document.createElement('script');

    script.innerHTML = `
    (function() {
      var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true; wa.defer = true;
      wa.src='https://tools.luckyorange.com/core/lo.js?site-id=${this.containerId}';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
      })();
    `;

    document.head.insertBefore(script, document.head.childNodes[0]);
    console.log('LuckOrange enabled');
    this.initialized = true;
  }

  // eslint-disable-next-line class-methods-use-this
  push(...args: SafeAny) {
    if (!(window as SafeAny)._loq) {
      (window as SafeAny)._loq = [];
    }

    (window as SafeAny)._loq.push(...args);
  }

  identify(id: string) {
    (window as SafeAny).LOQ = (window as SafeAny).LOQ || [];
    (window as SafeAny).LOQ.push([
      'ready',
      async (LO: SafeAny) => {
        // identify a visitor
        await LO.$internal.ready('visitor');
        LO.visitor.identify(id);
      }
    ]);
  }

  tag(tag: string) {
    this.push(['tag', tag]);
  }

  custom(object: SafeAny) {
    this.push(['custom', object]);
  }
}

export const usePTID = (isEnabled: boolean, hasLocalStorage: boolean) => {
  const today = new Date();
  return useQuery(
    ['PTID'],
    () =>
      hasLocalStorage
        ? storageGet('PTID').then((res) => {
            if (res) {
              const { PTID = '', expiry = 0 } = res;
              if (!PTID || expiry <= today.getTime()) {
                const newPTID = setNewPTID(hasLocalStorage);
                return newPTID.PTID;
              }
              return PTID;
            } else {
              const newPTID = setNewPTID(hasLocalStorage);
              return newPTID.PTID;
            }
          })
        : setNewPTID(hasLocalStorage).PTID,
    { enabled: isEnabled }
  );
};

const setNewPTID = (hasLocalStorage: boolean) => {
  const today = new Date();
  const newPTID = {
    PTID: randomString(10),
    expiry: addYears(today, 1).getTime()
  };
  if (hasLocalStorage) {
    storageSet('PTID', newPTID);
  }
  return newPTID;
};

export const luckyOrange = new LuckyOrangeClass();
