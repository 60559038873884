import { NumberFormatCustom } from '@components/NumberInput';
import { colors } from '@components/theme';
import { EKeyCode } from '@constants/keycode';
import { Box, Button, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { ExpandMore, FirstPage, LastPage, NavigateBefore, NavigateNext } from '@material-ui/icons';
import React, { useMemo } from 'react';

const pageSizeOptions = [12, 24, 50, 100];

type SwyftTablePaginationProps = {
  totalRows?: number;
  pageSize?: number | null;
  pageIndex?: number | null;
  hiddenChangePageIndex?: boolean;
  onPageIndexChange: (index: number) => void;
  onPageSizeChange: (size: number) => void;
  justifyContent?: string;
};

export const SwyftTablePagination = ({
  totalRows = 0,
  pageSize = 12,
  pageIndex = 1,
  hiddenChangePageIndex = false,
  onPageIndexChange,
  onPageSizeChange,
  justifyContent = 'space-around'
}: SwyftTablePaginationProps): JSX.Element => {
  const totalPage = useMemo(() => Math.ceil(totalRows / (pageSize || 12)), [totalRows, pageSize]);

  const handleSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onPageSizeChange(Number(event.target.value));
    onPageIndexChange(1);
  };

  const handlePageChange = (page: number) => {
    page = Math.min(page, totalPage);
    page = page ? page : 1;
    onPageIndexChange(page);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === EKeyCode.Enter) {
      handlePageChange(Number((e.target as any).value));
    }
  };

  return (
    <Box display='flex' justifyContent={hiddenChangePageIndex ? 'flex-start' : justifyContent}>
      <Box display={'flex'} alignItems={'center'} mr={2}>
        <Box pr={2}>
          <Typography variant='caption'>Show</Typography>
        </Box>
        <Select
          disableUnderline
          defaultValue={pageSizeOptions[0]}
          value={pageSize}
          onChange={handleSizeChange}
          IconComponent={ExpandMore}
          disabled={!totalPage}
        >
          {pageSizeOptions.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <Box pl={2}>
          <Typography variant='caption'>per page</Typography>
        </Box>
      </Box>
      {!hiddenChangePageIndex && (
        <Box display={'flex'} alignItems={'center'}>
          <Button
            className='swyft-page-icon'
            disabled={pageIndex === 1}
            onClick={() => handlePageChange(1)}
            size='small'
          >
            <FirstPage fontSize='small' />
          </Button>
          <Button
            className='swyft-page-icon'
            disabled={pageIndex === 1}
            onClick={() => handlePageChange((pageIndex || 1) - 1)}
            size='small'
          >
            <NavigateBefore fontSize='small' />
          </Button>
          <TextField
            value={pageIndex}
            onKeyDown={handleEnterPress}
            InputProps={{
              inputComponent: (props: any) =>
                (<NumberFormatCustom {...props} allowNegative={false} decimalSeparator={false} />) as any,
              disableUnderline: true,
              className: 'swyft-page-input'
            }}
            disabled={!totalPage || totalPage === 1}
          />
          <Box mx={0.5} color={colors.grey[900]}>
            <Typography variant='caption'>of {totalPage} </Typography>
          </Box>
          <Button
            className='swyft-page-icon'
            disabled={!totalPage || pageIndex === totalPage}
            onClick={() => handlePageChange((pageIndex || 1) + 1)}
            size='small'
          >
            <NavigateNext fontSize='small' />
          </Button>
          <Button
            className='swyft-page-icon'
            disabled={!totalPage || pageIndex === totalPage}
            onClick={() => handlePageChange(totalPage)}
            size='small'
          >
            <LastPage fontSize='small' />
          </Button>
        </Box>
      )}
    </Box>
  );
};
