import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

const CollapsedContext = createContext<boolean>(false);
CollapsedContext.displayName = 'CollapsedContext';

type SetCollapsedFunc = (value: boolean) => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const SetCollapsedContext = createContext<SetCollapsedFunc>(() => {});
SetCollapsedContext.displayName = 'SetCollapsedContext';

export const useCollapsed = (): [boolean, SetCollapsedFunc] => [
  useContext(CollapsedContext),
  useContext(SetCollapsedContext),
];

const MobileOpenContext = createContext<boolean>(false);
MobileOpenContext.displayName = 'MobileOpenContext';

type SetMobileOpenFunc = (value: boolean) => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const SetMobileOpenContext = createContext<SetMobileOpenFunc>(() => {});
SetMobileOpenContext.displayName = 'SetMobileOpenContext';

export const useMobileOpen = (): [boolean, SetMobileOpenFunc] => [
  useContext(MobileOpenContext),
  useContext(SetMobileOpenContext),
];

const BannerContext = createContext<{
  showBanner: boolean;
  bannerContent: ReactNode | string | null;
  setBannerState: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ showBanner: false, bannerContent: null, setBannerState: () => {} });
BannerContext.displayName = 'BannerContext';

export const useBannerContext = () => useContext(BannerContext);

export const MainLayoutProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [bannerContent] = useState<ReactNode | string | null>(false);

  const bannerContextValue = {
    showBanner,
    bannerContent,
    setBannerState: setShowBanner,
  };

  useEffect(() => {
    if (showBanner) {
      setTimeout(() => setShowBanner(false), 5000);
    }
  }, [showBanner]);

  return (
    <SetCollapsedContext.Provider value={setCollapsed}>
      <SetMobileOpenContext.Provider value={setMobileOpen}>
        <MobileOpenContext.Provider value={mobileOpen}>
          <CollapsedContext.Provider value={collapsed}>
            <BannerContext.Provider value={bannerContextValue}>
              {children}
            </BannerContext.Provider>
          </CollapsedContext.Provider>
        </MobileOpenContext.Provider>
      </SetMobileOpenContext.Provider>
    </SetCollapsedContext.Provider>
  );
};
