export const getConsentForCategory = (category: string) => {
  const termlyCacheString = localStorage.getItem('TERMLY_API_CACHE');

  if (!termlyCacheString) {
    return false;
  }

  try {
    const termlyCache = JSON.parse(termlyCacheString);
    const consentInfo = termlyCache['TERMLY_COOKIE_CONSENT']?.value;
    if (!consentInfo) {
      return false;
    }
    switch (category) {
      case 'analytics':
        return consentInfo.analytics === true;
      case 'advertising':
        return consentInfo.advertising === true;
      case 'essential':
        return consentInfo.essential === true;
      case 'performance':
        return consentInfo.performance === true;
      case 'social_networking':
        return consentInfo.social_networking === true;
    }
  } catch (e) {
    console.error('Error parsing Termly API Cache:', e);
    return false;
  }

  return false;
};
