import React from 'react';

interface MapProps {
  scriptLoaded: boolean;
}

const initState: MapProps = {
  scriptLoaded: false
};

export const MapContext = React.createContext<MapProps>(initState);
export const useMapContext = () => React.useContext(MapContext);
