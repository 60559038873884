import { SafeAny } from '@models/common';
import { Routes } from '@models/routes';
import { AnalyticsClient } from '@services/analytics/analyticsClient';
import { ClientType } from '@services/analytics/types';
import { history } from '@utils/history';
import { flattenRoutes } from '@utils/routes';
import { ReactElement, Suspense } from 'react';
import { Route as RouteComponent, RouteProps, Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ErrorRouter } from './ErrorPage';
import { LegalRouter } from './Legal';
import { QuestionnaireRoutes } from './Questionnaire';
import { ThankRoutes } from './Thankyou';

const routes: Routes = flattenRoutes([...QuestionnaireRoutes, ...ThankRoutes, ...ErrorRouter, ...LegalRouter]);

const AppRoute = ({ component: Component, ...rest }: RouteProps & { component: SafeAny }): ReactElement => {
  return (
    <RouteComponent
      {...rest}
      render={(props) => (
        <Suspense fallback={null}>
          <Component {...props} />
        </Suspense>
      )}
    />
  );
};

export const AnalyticsRouter = (props: SafeAny) => {
  const analyticsClient = new AnalyticsClient();
  analyticsClient.initClient(ClientType.MATOMO, props.siteId, props.siteUrl);
  AnalyticsClient.Client.setTenantId(props.tenantId);
  return (
    <Router history={AnalyticsClient.Client.connectToRoutes(history)}>
      <QueryParamProvider ReactRouterRoute={RouteComponent}>
        <Switch>
          {routes.map((r) => (
            <AppRoute exact component={r.component} path={r.path} key={r.path} />
          ))}
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};

export const NoAnalyticsRouter = () => {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={RouteComponent}>
        <Switch>
          {routes.map((r) => (
            <AppRoute exact component={r.component} path={r.path} key={r.path} />
          ))}
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};
