import { SafeAny } from '@models/common';
import * as Types from './types';
export abstract class BaseAnalyticsClient {
  // eslint-disable-next-line
  public init(siteId: SafeAny, siteUrl: string) {
    // NOT IMPLEMENTED
  }

  // eslint-disable-next-line
  public connectToRoutes(history: SafeAny): SafeAny {
    throw "NOT IMPLEMENTED"
  }

  // eslint-disable-next-line
  public trackPageView(payload: Types.PageViewPayload) {
    // NOT IMPLEMENTED
  }

  // eslint-disable-next-line
  public trackEvent(payload: Types.EventPayload) {
    // NOT IMPLEMENTED
  }

  // eslint-disable-next-line
  public setTenantId(value: string) {
    // NOT IMPLEMENTED
  }

  // eslint-disable-next-line
  public setQuestionnaireId(value: string) {
    // NOT IMPLEMENTED
  }
}
