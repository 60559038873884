export enum EColumnDataType {
  TEXT = 'text',
  NUMBER = 'number',
  IMAGE = 'image',
  CURRENCY = 'currency',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  TAG = 'tag',
  PERCENT = 'percent',
  CUSTOM = 'custom',
  ARRAY = 'array',
  TEMPLATE = 'template',
  MONTH = 'month',
  QUARTER = 'quarter',
  DURATION = 'duration',
}
