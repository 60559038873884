import { Routes } from '@models/routes';
import { lazy } from 'react';

const CookiePage = lazy(() =>
  import('@pages/legal/Cookies').then((p) => ({
    default: p.CookiesPage,
  }))
);

const PrivacyPage = lazy(() =>
  import('@pages/legal/Privacy').then((p) => ({
    default: p.PrivacyPage,
  }))
);

const TermsPage = lazy(() =>
  import('@pages/legal/Terms').then((p) => ({
    default: p.TermsPage,
  }))
);

export const LegalRouter: Routes = [
    {
        path: '/cookie-policy',
        component: CookiePage,
      },
      {
        path: '/privacy-policy',
        component: PrivacyPage,
      },
      {
        path: '/terms-and-conditions',
        component: TermsPage,
      },
    ];
