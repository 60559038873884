import { FeatureNames } from '@swyft/ui';
import { useAppContext } from './appState';

export interface Config {
  ServerUrl: string;
  TenantId: string;
  MatomoTag: string;
}

export function getConfig(): Config {
  return {
    ServerUrl: process.env.REACT_APP_SERVER_URL as string,
    TenantId: process.env.REACT_APP_TENANT_ID as string,
    MatomoTag: process.env.REACT_APP_MATOMO_TAG as string
  };
}

export const useFeature = (featureName: FeatureNames) => {
  const {
    state: { features }
  } = useAppContext();
  const feature = features.find((f) => f.name === featureName);
  if (!feature) {
    return {
      enabled: false,
      featureConfig: null
    };
  }
  const { enabled, featureConfig } = feature;
  return { enabled, featureConfig };
};
