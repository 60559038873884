import { Datepicker, Page, setOptions } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';

import React from 'react';

setOptions({
  theme: 'material',
  themeVariant: 'light'
});

export function Calendar({ date, setDate }: { date: any; setDate: (date: Date | null) => void }) {
  const handleSetDate = (e: any) => {
    setDate(e.date);
  };

  return (
    <Page>
      <Datepicker
        controls={['calendar']}
        display='inline'
        min={new Date()}
        firstDay={1}
        value={date}
        onCellClick={handleSetDate}
        defaultSelection={null}
      />
    </Page>
  );
}
