import { lazy } from 'react';
import { Routes } from '@models/routes';

const ErrorPage = lazy(() =>
  import('@pages/ErrorPage').then((p) => ({
    default: p.default,
  }))
);

export const ErrorRouter: Routes = [
  {
    path: '/error-page',
    component: ErrorPage,
  },
];
