import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from '@material-ui/core';
import { BaseMuiTheme } from '@theme';
import { AppProvider } from '@utils/appState';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { defaultLocale, dynamicActivate } from './i18n';
import { AppRoutes } from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10_000
    }
  }
});

export function App(): JSX.Element {
  React.useEffect(() => {
    dynamicActivate(defaultLocale);
  }, []);

  return (
    <ThemeProvider theme={BaseMuiTheme}>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
        </QueryClientProvider>
      </I18nProvider>
    </ThemeProvider>
  );
}
