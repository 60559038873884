import { padStart } from 'lodash-es';
import { AmPmTimeObj, Shift } from './model';

const forceHour = (hour: string) => {
  const h = String(Math.min(Math.max(+hour, 0), 12));
  return padStart(h, 2, '0');
};

const forceMinute = (minute: string) => {
  const m = String(Math.min(Math.max(+minute, 0), 59));
  return padStart(m, 2, '0');
};

export const _AmPmTo24Hour = ({ hour, minute, shift }: AmPmTimeObj) => {
  hour = forceHour(hour);
  minute = forceMinute(minute);
  if (shift === Shift.AM) {
    return `${padStart(String(+hour % 12), 2, '0')}:${minute}`;
  }
  return `${(+hour % 12) + 12}:${minute}`;
};

export const _24HourToAmPm = (value: string): AmPmTimeObj => {
  if (!value) {
    return {
      hour: '0',
      minute: '0',
      shift: Shift.AM,
      timeString: '',
    };
  }

  let shift = Shift.AM;
  let [hour, minute] = String(value).split(':');
  if (Number(hour) >= 12) {
    hour = String(Number(hour) % 12 || 12);
    shift = Shift.PM;
  }
  hour = forceHour(hour);
  minute = forceMinute(minute);

  return {
    hour,
    minute,
    shift,
    timeString: `${hour}:${minute} ${shift}`,
  };
};
