import { gtm } from '@services/gtm';
import { luckyOrange } from '@services/luckyOrange';
import { FeatureNames } from '@swyft/ui';
import { useAppContext } from '@utils/appState';
import { useFeature } from '@utils/config';
import { useEffect, useState } from 'react';
import { getConsentForCategory } from './ConsentCheck';

export const Analytics = () => {
  const { enabled: featureLOEnabled } = useFeature(FeatureNames.LuckyOrange);
  const {
    state: {
      luckyOrangeSiteId,
      questionnaireAnalytics: { gtmId }
    }
  } = useAppContext();

  const [hasAnalyticsConsent, setHasAnalyticsConsent] = useState(getConsentForCategory('analytics'));

  useEffect(() => {
    gtm.initialize({
      containerId: gtmId
    });
  }, [gtmId]);

  useEffect(() => {
    const handleConsentChange = () => {
      setHasAnalyticsConsent(getConsentForCategory('analytics'));
    };

    window.addEventListener('consentStatusUpdated', handleConsentChange);

    return () => {
      window.removeEventListener('consentStatusUpdated', handleConsentChange);
    };
  }, []);

  useEffect(() => {
    if (!featureLOEnabled || !hasAnalyticsConsent) {
      return;
    }
    luckyOrange.initialize({
      containerId: luckyOrangeSiteId as string
    });
  }, [luckyOrangeSiteId, featureLOEnabled, hasAnalyticsConsent]);

  return <></>;
};
