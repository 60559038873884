import { Box, Fab, useMediaQuery, useScrollTrigger, useTheme, Zoom } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { KeyboardArrowUp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useCollapsed } from './MainLayoutContext';

const SetScrollbarContext = React.createContext<any>(undefined);
export const useScrollBar = () => React.useContext(SetScrollbarContext);

export const MainContent = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [collapsed] = useCollapsed();
  const [contentNode, setContentNode] = useState<any>(null);
  const scrollbarRef = React.useRef<any>();

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const trigger = useScrollTrigger({
    target: contentNode || window,
    disableHysteresis: true,
    threshold: 100
  });

  const handleScrollToTop = () => {
    contentNode.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    setContentNode(document.querySelector('#swyft-data-content'));
  }, []);
  return (
    <Box display='flex' flex='1 1 auto' overflow='hidden' pt={10} pl={isSmDown ? 0 : collapsed ? 10 : 24}>
      <Box display='flex' flex='1 1 auto' overflow='hidden' bgcolor={grey[50]}>
        <Box flex='1 1 auto' height='100%' overflow='auto'>
          <div id='swyft-data-content' style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <SetScrollbarContext.Provider value={contentNode}>{children}</SetScrollbarContext.Provider>
          </div>
        </Box>
      </Box>
      <Box position='fixed' bottom='1.5rem' right='1.5rem'>
        <Zoom in={trigger}>
          <Fab size='small' color='secondary' onClick={handleScrollToTop}>
            <KeyboardArrowUp />
          </Fab>
        </Zoom>
      </Box>
    </Box>
  );
};
