import { Dictionary } from './common';

export interface AdminConfig {
  name: string;
  id: string;
  services: Services[];
}

export interface Services {
  name: string;
  features: Feature[];
  settings: Setting[];
}

export interface Feature {
  name: string;
  enabled: boolean;
  featureConfig: null | Dictionary;
}

export interface Setting {
  name: string;
  value: string;
}

export interface Currency {
  currencyName: string;
  currencySymbol: string;
}

export enum FeatureNames {
  Maps = 'Questionnaire.Maps',
  LuckyOrange = 'Questionnaire.LuckyOrange',
  Preview = 'Questionnaire.Preview',
  Embed = 'Questionnaire.Embed',
  Quote = 'Quote',
  Contact = 'Contact',
  Payment = 'Payment',
  Scheduling = 'Scheduling',
  Analytics = 'Analytics'
}
