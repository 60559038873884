export enum ClientType {
  MATOMO
}

export interface PageViewPayload {
  pageUrl: string,
  pageTitle: string
}

export enum EventCategory {
  ANSWER = 'Answer',
  CALL_TO_ACTION = 'CallToAction',
  COMPLETION = 'Completion',
  LEAD = 'Lead'
}

export enum CustomDimension {
  TenantId = 1,
  QuestionnaireId = 2
}

export interface EventPayload {
  category: EventCategory,
  eventAction: string,
  eventName: string,
}