import { SafeAny } from '@models/common';
import { Product } from '@models/product';
import { CompleteQuestionnaireReq, QuestionnaireElements } from '@models/questionnaire';
import {
  completeQuestionnaire,
  getAdminConfig,
  getOutcomePublic,
  getProductByIds,
  getQuestionnaireDetailsById,
  getThemeByQuestionnaireId
} from '@services/questionnaire';
import { EStorageKeys, storageGet, storageSet } from '@services/storage';
import { QuestionnaireDetails } from '@swyft/questionnaire';
import { generateId } from '@utils/generate-id';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';

const ADMIN_CONFIG = 'ADMIN_CONFIG';

export const useQuestionnaireDetails = (
  id: string,
  options?: Omit<UseQueryOptions<unknown, unknown, unknown, string[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery([id], () => getQuestionnaireDetailsById(id), {
    ...options
  }) as UseQueryResult<SafeAny, QuestionnaireDetails>;

export const useQuestionnaireState = (
  questionnaireId: string
): [UseQueryResult<SafeAny, SafeAny>, UseMutationResult<SafeAny, SafeAny, CompleteQuestionnaireReq, SafeAny>] => {
  const clientQuery = useQueryClient();
  return [
    useQuery([questionnaireId + '-state'], () =>
      storageGet(questionnaireId + '-state').then((res) => {
        if (res) {
          return res;
        } else {
          return storageSet(questionnaireId + '-state', {
            id: generateId(),
            questionnaireId,
            answerSelectionIds: [],
            questionFieldSubmissions: []
          });
        }
      })
    ),
    useMutation((state: CompleteQuestionnaireReq) => storageSet(questionnaireId + '-state', state), {
      onSuccess: () => clientQuery.invalidateQueries([questionnaireId + '-state'])
    })
  ];
};

export const useCurrentQuestionId = (questionnaireId: string) =>
  useQuery([questionnaireId + '-current'], () => storageGet(questionnaireId + '-current'));

export const useSetCurrentQuestionId = (questionnaireId: string) => {
  const clientQuery = useQueryClient();
  return useMutation((id: string) => storageSet(questionnaireId + '-current', id), {
    onSuccess: () => clientQuery.invalidateQueries([questionnaireId + '-current'])
  });
};

export const useCompleteQuestionnaire = () =>
  useMutation((params: CompleteQuestionnaireReq) => completeQuestionnaire(params));

export const useQuestionnaireActiveStep = (
  questionnaireId: string
): [UseQueryResult<SafeAny, SafeAny>, UseMutationResult<SafeAny, unknown, number, SafeAny>] => {
  const clientQuery = useQueryClient();
  return [
    useQuery([questionnaireId + '-activeStep'], () =>
      storageGet(questionnaireId + '-activeStep').then((res) => {
        if (res) {
          return res;
        } else {
          return storageSet(questionnaireId + '-activeStep', 1);
        }
      })
    ),
    useMutation((activeStep: number) => storageSet(questionnaireId + '-activeStep', activeStep), {
      onSuccess: () => clientQuery.invalidateQueries([questionnaireId + '-activeStep'])
    })
  ];
};

export const useQuestionnaireTheme = (
  questionnaireId: string,
  options?: Omit<UseQueryOptions<unknown, unknown, unknown, string[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery([`theme-${questionnaireId}`], () => getThemeByQuestionnaireId(questionnaireId), {
    ...options
  }) as UseQueryResult<SafeAny, QuestionnaireElements>;

export const useCurrentTheme = () =>
  useQuery([EStorageKeys.CURRENT_THEME], () => storageGet(EStorageKeys.CURRENT_THEME));

export const useOutcomePublic = (id: string) => useQuery([id], () => getOutcomePublic(id), { retry: false });

export const useProducts = (
  ids: string[],
  options?: Omit<UseQueryOptions<unknown, unknown, unknown, string[]>, 'queryKey' | 'queryFn'>
) =>
  useQuery([ids.join(',')], () => getProductByIds(ids), {
    ...options
  }) as UseQueryResult<SafeAny, Product[]>;

export const useGTMId = () => useQuery([EStorageKeys.GTM_ID], () => storageGet(EStorageKeys.GTM_ID));

export const useFacebookPixel = () =>
  useQuery([EStorageKeys.FACEBOOK_PIXEL], () => storageGet(EStorageKeys.FACEBOOK_PIXEL));

export const useTitle = () => useQuery([EStorageKeys.TITLE], () => storageGet(EStorageKeys.TITLE));

export const useCurrencyStorage = () =>
  useQuery(['GET_CURRENCY_STORAGE'], () => {
    return storageGet(EStorageKeys.CURRENCY).then((data) => {
      return { name: data['Currency.Name'], symbol: data['Currency.Symbol'] };
    });
  });

export const useAdminConfig = () => useQuery([ADMIN_CONFIG], () => getAdminConfig());
