import { createTheme } from '@material-ui/core';
import { MuiTheme } from '@swyft/ui';

export { backdropZIndex, boxShadow, colors } from '@swyft/ui';
export const BaseMuiTheme = createTheme({
  ...MuiTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1200,
      xl: 1400
    }
  }
});
