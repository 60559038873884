export enum EKeyCode {
  'Backspace' = 8,
  'Tab' = 9,
  'Enter' = 13,
  'Shift' = 16,
  'Ctrl' = 17,
  'Alt' = 18,
  'Pause/Break' = 19,
  'CapsLock' = 20,
  'Esc' = 27,
  'Space' = 32,
  'PageUp' = 33,
  'PageDown' = 34,
  'End' = 35,
  'Home' = 36,
  'Left' = 37,
  'Up' = 38,
  'Right' = 39,
  'Down' = 40,
  'Insert' = 45,
  'Delete' = 46,
  'Char0' = 48,
  'Char1' = 49,
  'Char2' = 50,
  'Char3' = 51,
  'Char4' = 52,
  'Char5' = 53,
  'Char6' = 54,
  'Char7' = 55,
  'Char8' = 56,
  'Char9' = 57,
  'A' = 65,
  'B' = 66,
  'C' = 67,
  'D' = 68,
  'E' = 69,
  'F' = 70,
  'G' = 71,
  'H' = 72,
  'I' = 73,
  'J' = 74,
  'K' = 75,
  'L' = 76,
  'M' = 77,
  'N' = 78,
  'O' = 79,
  'P' = 80,
  'Q' = 81,
  'R' = 82,
  'S' = 83,
  'T' = 84,
  'U' = 85,
  'V' = 86,
  'W' = 87,
  'X' = 88,
  'Y' = 89,
  'Z' = 90,
  'Windows' = 91,
  'RightClick' = 93,
  'Numpad0' = 96,
  'Numpad1' = 97,
  'Numpad2' = 98,
  'Numpad3' = 99,
  'Numpad4' = 100,
  'Numpad5' = 101,
  'Numpad6' = 102,
  'Numpad7' = 103,
  'Numpad8' = 104,
  'Numpad9' = 105,
  'Numpad*' = 106,
  'Numpad+' = 107,
  'Numpad-' = 109,
  'Numpad.' = 110,
  'Numpad/' = 111,
  'F1' = 112,
  'F2' = 113,
  'F3' = 114,
  'F4' = 115,
  'F5' = 116,
  'F6' = 117,
  'F7' = 118,
  'F8' = 119,
  'F9' = 120,
  'F10' = 121,
  'F11' = 122,
  'F12' = 123,
  'NumLock' = 144,
  'ScrollLock' = 145,
  'MyComputer' = 182,
  'MyCalculator' = 183,
  ';' = 186,
  '=' = 187,
  ',' = 188,
  '-' = 189,
  '.' = 190,
  '/' = 191,
  '`' = 192,
  '[' = 219,
  '\\' = 220,
  ']' = 221,
  "'" = 222
}
