import { SafeAny } from '@models/common';
import { Product } from '@models/product';
import { CompleteQuestionnaireReq, QuestionnaireTheme } from '@models/questionnaire';
import { Lead, OutcomePublic, QuestionnaireDetails } from '@swyft/questionnaire';
import { AdminConfig } from '@swyft/ui';
import API from './http';

export const getQuestionnaireDetailsById = (id: string): Promise<QuestionnaireDetails> => {
  return API.get(`/api/Assessments/public/Questionnaire/${id}`).then((res) => res.data);
};

export const getOutcomePublic = (id: string): Promise<OutcomePublic> => {
  return API.get(`/api/Assessments/public/Outcome/${id}`)
    .then((res) => res.data)
    .catch(() => null);
};

export const completeQuestionnaire = (params: CompleteQuestionnaireReq) =>
  API.post('/api/Assessments/public/CompletedQuestionnaire', params).then((res) => res.data);

export const getProductByIds = (ids: string[]): Promise<Product[]> => {
  const productIds = ids.join('&productIds=');
  return API.get(`/api/pricing/public/Product/list?productIds=${productIds}`).then((res) => res.data);
};

export const getThemeById = (id: string): Promise<QuestionnaireTheme> =>
  API.get(`/api/assessments/public/theme/${id}`).then((res) => res.data);

export const getThemeByQuestionnaireId = (id: string): Promise<QuestionnaireTheme> =>
  API.get(`/api/assessments/public/theme/questionnaire/${id}`).then((res) => res.data);

export const createLead = (lead: Lead): Promise<SafeAny> =>
  API.post('/api/store/public/Lead', lead).then((res) => res.data);

export const getAdminConfig = (): Promise<AdminConfig> => {
  return API.get('/api/config').then((res) => res.data);
};
