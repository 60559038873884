import { lazy } from 'react';
import { Routes } from '@models/routes';

const Questionnaires = lazy(() =>
  import('@pages/questionnaire/Questionnaire').then((p) => ({
    default: p.Questionnaire,
  }))
);

export const QuestionnaireRoutes: Routes = [
  {
    path: '/questionnaire/:id',
    component: Questionnaires,
  },
];
