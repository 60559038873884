import { SafeAny } from '@models/common';
import { QuestionnaireTheme } from '@models/questionnaire';
import { useAdminConfig } from '@queries/questionnaire';
import { OutcomePublic, QuestionnaireDetails } from '@swyft/questionnaire';
import { ASSESSMENT, CORE, Feature, PRICING, STORE } from '@swyft/ui';
import localforage from 'localforage';
import { isEqual } from 'lodash-es';
import * as React from 'react';

const IS_WHITE_LABEL = 'IsWhiteLabel';

interface AdminConfig {
  isWhiteLabel: boolean;
  name: string;
}

interface QuestionnaireAnalytics {
  questionnaireName: string;
  gtmId: string;
  facebookPixelId: string;
}

interface IAppState {
  loading: boolean;
  questionnaireAnalytics: QuestionnaireAnalytics;
  luckyOrangeSiteId: string;
  adminConfig: AdminConfig;
  hasLocalStorage: boolean;
  theme: QuestionnaireTheme;
  currency: {
    name: string;
    symbol: string;
  };
  features: Feature[];
  questionnaireData: QuestionnaireDetails | null;
  outcomeData: OutcomePublic | null;
  PTID: string | null;
}

const initState: IAppState = {
  loading: false,
  questionnaireAnalytics: {
    questionnaireName: '',
    gtmId: '',
    facebookPixelId: ''
  },
  luckyOrangeSiteId: '',
  adminConfig: { isWhiteLabel: true, name: '' },
  hasLocalStorage: false,
  theme: {} as QuestionnaireTheme,
  currency: {
    name: '',
    symbol: ''
  },
  features: [],
  questionnaireData: null,
  outcomeData: {} as OutcomePublic,
  PTID: ''
};

enum AppActionTypes {
  Loading,
  Unloading,
  ToggleLoading,
  SetLuckyOrangeSite,
  SetAdminConfig,
  SetHasLocalStorage,
  SetQuestionnaireAnalytics,
  SetTheme,
  SetCurrency,
  SetFeature,
  SetQuestionnaireData,
  SetOutcomeData,
  SetPTID
}

interface ContextProps {
  state: IAppState;
  dispatch?: SafeAny;
  openLoading?: SafeAny;
  closeLoading?: SafeAny;
  toggleLoading?: SafeAny;
  setLuckyOrangeSiteId?: SafeAny;
  setHasLocalStorage?: SafeAny;
  setQuestionnaireAnalytics?: SafeAny;
  setTheme?: SafeAny;
  setCurrency?: SafeAny;
  setQuestionnaireData?: SafeAny;
  setOutcomeData?: SafeAny;
  setPTID?: SafeAny;
}

const AppContext = React.createContext<ContextProps>({ state: initState });
const useAppContext = () => React.useContext(AppContext);

const stateReducer = (state = initState, action: SafeAny) => {
  switch (action.type) {
    case AppActionTypes.Loading:
      return {
        ...state,
        loading: true
      };
    case AppActionTypes.Unloading:
      return {
        ...state,
        loading: false
      };
    case AppActionTypes.ToggleLoading:
      return {
        ...state,
        loading: !state.loading
      };
    case AppActionTypes.SetLuckyOrangeSite:
      return {
        ...state,
        luckyOrangeSiteId: action.payload
      };
    case AppActionTypes.SetAdminConfig:
      return {
        ...state,
        adminConfig: action.payload
      };
    case AppActionTypes.SetHasLocalStorage:
      return {
        ...state,
        hasLocalStorage: action.payload
      };
    case AppActionTypes.SetQuestionnaireAnalytics:
      return {
        ...state,
        questionnaireAnalytics: action.payload
      };
    case AppActionTypes.SetTheme:
      return {
        ...state,
        theme: action.payload
      };
    case AppActionTypes.SetCurrency: {
      return {
        ...state,
        currency: action.payload
      };
    }
    case AppActionTypes.SetFeature:
      return {
        ...state,
        features: action.payload
      };
    case AppActionTypes.SetQuestionnaireData:
      return {
        ...state,
        questionnaireData: action.payload
      };
    case AppActionTypes.SetOutcomeData:
      return {
        ...state,
        outcomeData: action.payload
      };
    case AppActionTypes.SetPTID:
      return {
        ...state,
        PTID: action.payload
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const AppProvider = ({ children }: { children: SafeAny }) => {
  const [state, dispatch] = React.useReducer(stateReducer, initState);
  const openLoading = React.useCallback(() => dispatch({ type: AppActionTypes.Loading }), [dispatch]);
  const closeLoading = React.useCallback(() => dispatch({ type: AppActionTypes.Unloading }), [[dispatch]]);
  const toggleLoading = React.useCallback(() => dispatch({ type: AppActionTypes.ToggleLoading }), [dispatch]);
  const setLuckyOrangeSiteId = React.useCallback(
    (luckyOrangeSiteId: string) => dispatch({ type: AppActionTypes.SetLuckyOrangeSite, payload: luckyOrangeSiteId }),
    [dispatch]
  );
  const setAdminConfig = React.useCallback(
    (adminConfig: AdminConfig) => dispatch({ type: AppActionTypes.SetAdminConfig, payload: adminConfig }),
    [dispatch]
  );
  const setQuestionnaireAnalytics = React.useCallback(
    (questionnaireAnalytics: QuestionnaireAnalytics) =>
      dispatch({ type: AppActionTypes.SetQuestionnaireAnalytics, payload: questionnaireAnalytics }),
    [dispatch]
  );
  const setHasLocalStorage = React.useCallback(
    (hasLocalStorage: boolean) => dispatch({ type: AppActionTypes.SetHasLocalStorage, payload: hasLocalStorage }),
    [dispatch]
  );
  const setTheme = React.useCallback(
    (theme: QuestionnaireTheme) => dispatch({ type: AppActionTypes.SetTheme, payload: theme }),
    [dispatch]
  );
  const setCurrency = React.useCallback(
    (currency: SafeAny) => dispatch({ type: AppActionTypes.SetCurrency, payload: currency }),
    [dispatch]
  );
  const setFeatures = React.useCallback(
    (features: Feature[]) => dispatch({ type: AppActionTypes.SetFeature, payload: features }),
    [dispatch]
  );
  const setQuestionnaireData = React.useCallback(
    (questionnaireData: QuestionnaireDetails | null) =>
      dispatch({ type: AppActionTypes.SetQuestionnaireData, payload: questionnaireData }),
    [dispatch]
  );
  const setOutcomeData = React.useCallback(
    (outcomeData: OutcomePublic | null) => dispatch({ type: AppActionTypes.SetOutcomeData, payload: outcomeData }),
    [dispatch]
  );
  const setPTID = React.useCallback(
    (PTID: string | null) => dispatch({ type: AppActionTypes.SetPTID, payload: PTID }),
    [dispatch]
  );

  const { data: adminConfigData, isLoading } = useAdminConfig();

  localforage
    .ready()
    .then(() => {
      if (!state.hasLocalStorage) {
        setHasLocalStorage(true);
      }
    })
    .catch(() => {
      if (state.hasLocalStorage) {
        setHasLocalStorage(false);
      }
    });

  React.useEffect(() => {
    if (!adminConfigData) {
      return;
    }
    const { features, currency, adminConfig } = state;
    const { services, name } = adminConfigData;

    const coreFeatures = services.find((s) => s.name === CORE);
    const isWhiteLabelConfig = coreFeatures?.settings?.find((s) => s.name === IS_WHITE_LABEL)?.value || '';
    const assessmentFeatures = services.find((s) => s.name === ASSESSMENT);
    const storeFeatures = services.find((s) => s.name === STORE);
    const pricingFeatures = services.find((s) => s.name === PRICING);
    const currencySetting = pricingFeatures?.settings;

    const customerFeatures = [...(storeFeatures?.features || []), ...(assessmentFeatures?.features || [])];

    const _adminConfig = { isWhiteLabel: isWhiteLabelConfig === 'True', name };

    if (!isEqual(_adminConfig, adminConfig)) {
      setAdminConfig(_adminConfig);
    }
    if (!isEqual(customerFeatures, features)) {
      setFeatures(customerFeatures);
    }
    if (currencySetting) {
      const currencyName = currencySetting.find((c) => c.name === 'Currency.Name')?.value || '';
      const currencySymbol = currencySetting.find((c) => c.name === 'Currency.Symbol')?.value || '';
      const currencyConfig = {
        name: currencyName,
        symbol: currencySymbol
      };
      if (!isEqual(currencyConfig, currency)) {
        setCurrency(currencyConfig);
      }
    }
  }, [adminConfigData]);

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      openLoading,
      closeLoading,
      toggleLoading,
      setLuckyOrangeSiteId,
      setHasLocalStorage,
      setQuestionnaireAnalytics,
      setTheme,
      setQuestionnaireData,
      setOutcomeData,
      setPTID
    }),
    [
      state,
      dispatch,
      openLoading,
      closeLoading,
      toggleLoading,
      setLuckyOrangeSiteId,
      setHasLocalStorage,
      setQuestionnaireAnalytics,
      setTheme,
      setQuestionnaireData,
      setOutcomeData,
      setPTID
    ]
  );

  if (isLoading) {
    return null;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppProvider, useAppContext };
