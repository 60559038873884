import { getConfig } from '@utils/config';
import { extractError } from '@utils/error';
import Axios from 'axios';
import { toast } from 'react-toastify';

const axios = Axios.create({
  baseURL: getConfig().ServerUrl || undefined,
  timeout: 30000
});

axios.interceptors.request.use(
  async function (config) {
    if (getConfig().TenantId) {
      config.headers.TenantId = getConfig().TenantId;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, async (error) => {
  const errors = extractError(error.response.data);
  if (errors && !errors.includes('Tenant is not active')) {
    toast.error(errors.join(', '), {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
  return Promise.reject(error);
});

export default axios;
