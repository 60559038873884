import { CircularProgress, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash-es';
import React, { useCallback } from 'react';

type SearchBoxProps = {
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  searchInputChange: (value: string) => void;
  setSearchValue?: (value: string) => void;
  valueChange?: (value: any) => void;
  options?: any[];
  getOptionLabel?: (item: any) => string;
  getValue?: (item: any) => string;
  searchAnyKeyword?: boolean;
  className?: any;
  defaultValue?: any;
  setPage?: (value: number) => void;
  freeSolo?: boolean;
  noOptionsText?: string;
  loadingText?: boolean;
  searchOptionValue?: boolean;
};

const useStyles = makeStyles({
  option: {
    fontSize: '0.875rem'
  }
});

export const SearchBox = ({
  placeholder,
  disabled,
  loading,
  searchInputChange,
  setSearchValue,
  valueChange,
  options = [],
  getValue = (i) => i.name,
  getOptionLabel = (i) => i.name,
  searchAnyKeyword = false,
  className,
  defaultValue,
  setPage,
  freeSolo = true,
  noOptionsText = 'No options',
  loadingText = false,
  searchOptionValue = false
}: SearchBoxProps) => {
  const classes = useStyles();

  const [isFocus, setIsFocus] = React.useState(false);
  const [valueSelected, setValueSelected] = React.useState<string>(defaultValue);
  const setSearchValueDebounce = debounce((event, newInputValue) => {
    if (setSearchValue) setSearchValue(newInputValue.trim());
  }, 500);
  const setInputChange = useCallback((event: any, newInputValue: any) => {
    searchInputChange(newInputValue);
    setSearchValueDebounce(event, newInputValue);
  }, []);

  return (
    <Autocomplete
      open={isFocus && !!defaultValue}
      classes={{
        option: classes.option,
        ...className
      }}
      disabled={disabled}
      value={valueSelected}
      inputValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label=''
          placeholder={placeholder}
          variant='outlined'
          size='small'
          InputProps={{
            ...params.InputProps,
            className: 'search-box',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='primary' size={20} />
                ) : defaultValue ? (
                  params.InputProps.endAdornment
                ) : null}
              </React.Fragment>
            )
          }}
        />
      )}
      openText='open'
      autoComplete
      blurOnSelect
      includeInputInList
      onChange={(event: any, newValue: any | null) => {
        if (valueChange) {
          const value = searchAnyKeyword ? getValue(newValue) || newValue || '' : getValue(newValue);
          if (searchOptionValue) {
            const label = getOptionLabel(newValue);
            setValueSelected(label);
          } else setValueSelected(value);
          valueChange(value);
          if (setPage) {
            setPage(1);
          }
        }
      }}
      onInputChange={(event: any, newInputValue: any) => setInputChange(event, newInputValue)}
      getOptionLabel={(option) => {
        const label = getOptionLabel(option);
        return searchAnyKeyword ? label || valueSelected : label;
      }}
      options={options}
      freeSolo={freeSolo}
      noOptionsText={noOptionsText}
      forcePopupIcon={false}
      loading={loadingText && loading}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    />
  );
};
