import { SafeAny } from "@models/common";
import { BaseAnalyticsClient } from "./base";
import { MatomoClient } from "./matomoClient";
import * as Types from './types';

export class AnalyticsClient {
  public static Client: BaseAnalyticsClient = new MatomoClient();
  public initClient(smsClientType: Types.ClientType, siteId: SafeAny, siteUrl: string) {
    switch (smsClientType) {
      case Types.ClientType.MATOMO:
        AnalyticsClient.Client = new MatomoClient();
        AnalyticsClient.Client.init(siteId, siteUrl);
      }
  }
}
