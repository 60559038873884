import { lazy } from 'react';
import { Routes } from '@models/routes';

const ThankPage = lazy(() =>
  import('@pages/thankyou').then((p) => ({
    default: p.ThankPage,
  }))
);

export const ThankRoutes: Routes = [
  {
    path: '/questionnaire-outcome/:id',
    component: ThankPage,
  },
];
