import { SafeAny } from '@models/common';
import ReactPiwik from 'react-piwik';
import { BaseAnalyticsClient } from './base';
import * as Types from './types';

let piwik = {}
let alreadyInit = false;
export class MatomoClient extends BaseAnalyticsClient {
  public constructor() {
    super();
  }

  public init(siteId: SafeAny, siteUrl: string): void {
    if (alreadyInit || !siteId || !siteUrl)
      return;
    piwik = new ReactPiwik({
      url: siteUrl,
      siteId,
    });
    const script = document.createElement('script');
    script.innerHTML = `
    var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="` + siteUrl + `";
        _paq.push(['setTrackerUrl', u+'/matomo.php']);
        _paq.push(['setSiteId', '` + siteId + `']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='//cdn.matomo.cloud/` + siteUrl.replace('https://', '') +`/matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    `;
    document.head.insertBefore(script, document.head.childNodes[0]);
    alreadyInit = true;
    console.log('Analytics tracking enabled');
  }

  public connectToRoutes(history: SafeAny): SafeAny {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return piwik.connectToHistory(history);   
  }

  public trackPageView(payload: Types.PageViewPayload) {
    if (alreadyInit) {
      ReactPiwik.push(['setCustomUrl', payload.pageUrl]);
      ReactPiwik.push(['setDocumentTitle', payload.pageTitle]);
      ReactPiwik.push(['trackPageView']);
    }
  }

  public setTenantId(value: string): void {
    if (alreadyInit) {
      ReactPiwik.push(['setCustomDimension', Types.CustomDimension.TenantId, value])
    }
  }

  public setQuestionnaireId(value: string): void {
    if (alreadyInit) {
      ReactPiwik.push(['setCustomDimension', Types.CustomDimension.QuestionnaireId, value])
    }
  }

  public trackEvent(payload: Types.EventPayload) {
    if (alreadyInit) {
      ReactPiwik.push([
        'trackEvent', 
        payload.category, 
        payload.eventAction, 
        payload.eventName
      ]);    
    }
  }
}
