import { TableCell, TableSortLabel } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { EOrderDirection } from '@models/table';
import React, { ReactElement } from 'react';
import { Column, FunctionChildren } from '../models/types';

type TableHeaderProps = {
  children: FunctionChildren | ReactElement;
};

export const SwyftTableHeader = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children
}: TableHeaderProps): JSX.Element => <div />;

const mapOrderDirection = {
  [EOrderDirection.Ascending]: 'asc',
  [EOrderDirection.Descending]: 'desc'
};

type SwyftTableInnerHeaderProps = {
  col: Column;
  sortField?: string | null;
  sortDirection?: string | null;
  onSortChange: (value: string) => void;
  width?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

export const SwyftTableInnerHeader = ({
  col,
  sortField,
  sortDirection,
  onSortChange,
  width,
  align
}: SwyftTableInnerHeaderProps): JSX.Element => (
  <TableCell key={col.name} width={width} align={align}>
    {!col.sortable ? (
      !col.templateHeader ? (
        col.header
      ) : (
        col.templateHeader
      )
    ) : (
      <TableSortLabel
        active={sortField === col.name}
        hideSortIcon={false}
        direction={
          (sortField === col.name
            ? mapOrderDirection[sortDirection as EOrderDirection] || EOrderDirection.Descending
            : EOrderDirection.Descending) as any
        }
        onClick={() => onSortChange(col.name)}
        IconComponent={ExpandMore}
      >
        {!col.templateHeader ? col.header : col.templateHeader}
      </TableSortLabel>
    )}
  </TableCell>
);
