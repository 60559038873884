import { SafeAny } from '@models/common';
import localforage from 'localforage';

export enum EStorageKeys {
  QUESTIONNAIRE_STATE = 'QUESTIONNAIRE_STATE',
  CURRENT_QUESTIONNAIRE_ID = 'CURRENT_QUESTIONNAIRE_ID',
  CURRENT_THEME = 'CURRENT_THEME',
  GTM_ID = 'GTM_ID',
  CURRENCY = 'CURRENCY',
  TITLE = 'TITLE',
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL'
}

export const storageSet = (key: EStorageKeys | string, value: SafeAny) => {
  return localforage.setItem(key, value);
};

export const storageGet = <T = SafeAny>(key: EStorageKeys | string): Promise<T | null> => {
  return localforage.getItem<T>(key);
};

export const storageRemove = (key: EStorageKeys | string) => {
  return localforage.removeItem(key);
};

export const storageClear = (keys?: string[]) => {
  if (!keys) {
    return localforage.clear();
  }

  return Promise.all(keys.map((key) => localforage.removeItem(key)));
};
