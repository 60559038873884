import { useAppContext } from '@utils/appState';
import React from 'react';
import { Helmet } from 'react-helmet';

const Favicon = () => {
  const {
    state: {
      adminConfig: { isWhiteLabel, name: tenantName },
      questionnaireAnalytics: { questionnaireName: title },
      theme
    }
  } = useAppContext();
  const faviconIcon = theme?.faviconUrl || '';

  return (
    <Helmet>
      <link
        rel='icon'
        type='image/png'
        href={faviconIcon ? faviconIcon : process.env.PUBLIC_URL + '/assets/symbol_pipelyne.png'}
        sizes='16x16'
      />
      <title>{title}</title>
      <meta name='description' content={isWhiteLabel ? tenantName : 'Pipelyne'} />
      {/* for linkedin */}
      <meta property='og:title' content={title} />
      <meta name='description' property='og:description' content={isWhiteLabel ? tenantName : 'Pipelyne'} />
      <meta property='og:type' content='website' />
    </Helmet>
  );
};

export default Favicon;
