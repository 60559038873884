import { Box, makeStyles, TextField, TextFieldProps, Typography } from '@material-ui/core';
import React from 'react';
import { colors } from './theme';

type TextFieldWithTextProps = {
  startText?: string;
  startTextWidth?: number;
  endText?: string;
  bgColor?: string;
  textClassName?: string;
  textAction?: () => void;
} & TextFieldProps;

export const TextFieldWithText = ({
  startText,
  startTextWidth = 115,
  endText,
  bgColor = colors.primary[50],
  textClassName = '',
  textAction,
  ...textField
}: TextFieldWithTextProps) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      alignItems='center'
      border={`1px solid ${colors.grey[100]}`}
      bgcolor={bgColor}
      borderRadius={4}
      className={classes.customTextField}
      overflow='hidden'
    >
      {startText && (
        <Box
          width={startTextWidth}
          minHeight={40}
          display='flex'
          alignItems='center'
          pl={1}
          borderRight={`1px solid ${colors.grey[100]}`}
        >
          <Typography variant='body2'>{startText}</Typography>
        </Box>
      )}
      <Box
        bgcolor={colors.white}
        width={`calc(100% - ${startTextWidth}px)`}
        minHeight={40}
        display='flex'
        alignItems='center'
      >
        <TextField
          {...textField}
          variant='standard'
          size='medium'
          InputProps={{ className: 'swyft-input', disableUnderline: true }}
        />
      </Box>
      {endText && (
        <Box
          width={startTextWidth}
          height={40}
          display='flex'
          alignItems='center'
          justifyContent='center'
          className={textClassName}
          onClick={() => (textAction ? textAction() : null)}
        >
          <Typography variant='body2' className='whiteText'>
            {endText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  customTextField: {
    '&:focus-within': {
      border: `1px solid ${colors.primary.main}!important`
    },
    '&:hover': {
      borderColor: colors.grey[700]
    }
  }
});
